import { createStore } from 'vuex'

import api from '@/services/api'
import cookies from '@/helpers/cookies'
import logger from '@/helpers/logger'

import { AUTH_TOKEN } from '@/constants/cookies'

import deals from './modules/deals'

import mixpanel from '@/helpers/mixpanel.js'

import axios from 'axios'

const triggerLoginEvents = function (customer) {

  if (customer.imported && !customer.lastLogin) {
    mixpanel.identify(customer.id)
    // mixpanel.alias(customer.email)

    mixpanel.people.set({
      "$email": customer.email,
      "$name" : `${customer.firstName} ${customer.lastName}`,
      "$phone": customer.phone || "",
      "Sign up date": (new Date()).toISOString()
    });   
  }
  
  mixpanel.track("Log in");
  
  window._hsq.push(["trackEvent", { 
    id: "Portal – Log in"
  }]);

  mixpanel.identify(customer.id);
  // mixpanel.alias(customer.email);

  // const urlParams = new URLSearchParams(window.location.search);
  // urlParams.get('aid') && window.analytics?.setAnonymousId && window.analytics.setAnonymousId(urlParams.get('aid')) && window.analytics.alias(urlParams.get('aid'))

  window.analytics?.alias(customer.email)
  window.analytics.identify(customer.id, {
    name: `${customer.firstName} ${customer.lastName}`,
    email: customer.email
  })
  window.analytics.track("Identified");

  mixpanel.people.set({
    "$name": `${customer.firstName} ${customer.lastName}`,
    "$email": customer.email
  })

  window._hsq.push(["identify",{
    email: customer.email
  }]);
}



export default createStore({
  modules: {
    deals
  },

  state: {
    appIsReady: false,
    customer: null,
    notifications: [],
    activeModal: null,
    distributions: null,
    query: {}
  },

  mutations: {
    initApplication (state) {
      logger('MUTATION: initApplication')
      state.appIsReady = true
    },

    updateCustomerData (state, data) {
      logger('MUTATION: updateCustomerData:', data)
      state.customer = data
    },

    updateNotifications (state, notification) {
      state.notifications.push(notification)
    },

    updateActiveModal (state, modal) {
      state.activeModal = modal
    },

    updateSearchQuery (state, query) {
      state.query = query
    },
    
    updateDistributions(state, distributions) {
      state.distributions = distributions;
    },
  },

  actions: {
    async init ({ dispatch, commit }) {
      logger('ACTION: init()')

      const params = window.location.search.slice(1).split('&').reduce((acc, s) => {
        const [k, v] = s.split('=')
        return Object.assign(acc, {[k]: v})
      }, {})

      commit('updateSearchQuery', params)

      if (cookies.get(AUTH_TOKEN)) {
        await dispatch('authorizeCustomer', cookies.get(AUTH_TOKEN))
      }

      commit('initApplication')
    },

    async authorizeCustomer ({ commit }, token) {
      logger('ACTION: authorizeCustomer()', token)
      
      api.setAuthorizationHeaders(token)
      
      try {
        const customer = await api.getCustomer()

        if (customer) {
          cookies.set(AUTH_TOKEN, token)
          commit('updateCustomerData', customer)
          
          triggerLoginEvents(customer)

          return true
        }
      } catch(e) {
        console.log(e);
        cookies.delete(AUTH_TOKEN)
        commit('updateCustomerData', null)
        return false
      }
    },

    async authorizeCustomerByOneTimeCode ({ commit }, token) {
      logger('ACTION: authorizeCustomerByOneTimeCode()', token)
      
      try {
        const response = await api.authorizeCustomerByOneTimeCode({ token })
        
        cookies.set(AUTH_TOKEN, response.token)
        commit('updateCustomerData', response.profile)

        triggerLoginEvents(response.profile)

        return true

      } catch(e) {
        console.log(e);
        cookies.delete(AUTH_TOKEN)
        commit('updateCustomerData', null)
        return false
      }
    },

    async deauthorizeCustomer (context, token) {
      logger('ACTION: deauthorizeCustomer()', token)
      cookies.delete(AUTH_TOKEN)
      window.location.reload()
    },

    async createCustomer ({ commit }, payload) {
      logger('ACTION: createCustomer()')

      let { data } = await axios.get('https://flow.janover.ventures/get-ip');
      let ip = data.ip

      let hsContext = {
        pageUri: window.location.href,
        pageName: "JV Portal sign up"
      }

      if (ip !== undefined) hsContext.ipAddress = ip

      window._hsq.push(["identify",{
        email: payload.email
      }])
      
      window._hsq.push(["trackEvent", { 
        id: "Portal – Sign up"
      }])

      window.analytics.track("Sign Up – In-portal borrower form");
      

      payload.hsContext = hsContext

      const { token, profile } = await api.createCustomer(payload)

      if (profile) {
        api.setAuthorizationHeaders(token)
        cookies.set(AUTH_TOKEN, token)

        // smartlookClient.identify(profile.id, {
        //   name: `${profile.firstName} ${profile.lastName}`,
        //   email: profile.email
        // })
        window.analyitics?.alias(profile.email)
        window.analytics.identify(profile.id, {
          name: `${profile.firstName} ${profile.lastName}`,
          email: profile.email
        })
        mixpanel.identify(profile.id)

        mixpanel.people.set({
          "$email": profile.email,
          "$name" : `${profile.firstName} ${profile.lastName}`,
          "$phone": profile.phone || "",
          "Sign up date": (new Date()).toISOString()
        });
        
        commit('updateCustomerData', profile)
        return true
      } else {
        return false
      }
    },

    async checkDistributions({ commit }, payload) {
      try {
        const response = await api.checkDistributions(payload.dealId, payload.dealData);
        commit('updateDistributions', response);
      } catch (error) {
        console.error(error);
      }
    },

    async loginByEmail (context, email) {
      logger('ACTION: loginByEmail()')

      const response = await api.loginByEmail({
        email
      })

      return response
    },

    async getCustomer ({ commit }) {
      logger('ACTION: getCustomer()')
      
      const customer = await api.getCustomer()

      if (customer) {
        commit('updateCustomerData', customer)
      }
    },

    async updateCustomer ({ commit }, payload) {
      logger('ACTION: updateCustomer()')

      const customer = await api.updateCustomer(payload)

      if (customer) {
        commit('updateCustomerData', customer)
      }
    },

    async uploadFile (context, payload) {
      logger('ACTION: uploadFile()')

      const url = await api.uploadFile(payload)

      return url
    },

    async notify ({ commit }, payload) {
      if (commit.type == "error") {
        mixpanel.track("Error", {
          "Error message": commit.message 
        })
      }
      commit('updateNotifications', payload)
    },

    showModal ({ commit }, modal) {
      commit('updateActiveModal', modal)
    },

    hideModal ({ commit }) {
      commit('updateActiveModal', null)
    }
  },

  getters: {
    appIsReady (state) {
      return state.appIsReady
    },

    isAuth (state) {
      return !!state.customer
    },

    customer (state) {
      return state.customer
    },

    notifications (state) {
      return state.notifications
    },

    activeModal (state) {
      return state.activeModal
    },

    query (state) {
      return state.query
    },

    distributions (state) {
      return state.distributions
    }
  }
})
