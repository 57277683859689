<template>
	<div class="modals-wrap" v-if="modal" @click="closeModal">
		<div class="modal" @click.stop>
			<div class="close" @click="closeModal"></div>
			<component :is="modal.name" :key="modal.name" :modalData="modal.data" @closed="closeModal" />
		</div>
	</div>
</template>



<script>

	import { mapGetters, mapActions } from 'vuex'

	import SubmitDeal from '@/components/ModalSubmitDeal'
	import Welcome from '@/components/ModalWelcome'
	import NewDeal from '@/components/ModalNewDeal'
	import AcceptQuote from '@/components/ModalAcceptQuote'
	
	export default {
		name: 'ModalsBar',

		computed: {
			...mapGetters({
				'modal': 'activeModal'
			})
		},

		components: {
			SubmitDeal,
			Welcome,
			NewDeal,
			AcceptQuote
		},

		methods: {
			...mapActions([
				'hideModal'
			]),

			closeModal () {
				this.hideModal()
			}
		}
	}

</script>



<style lang="scss">
	
	.modals-wrap {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(0,0,0,0.2);
		/* cursor: pointer; */
		z-index: 99;
		display: flex;
		align-items: center;
		justify-content: center;
		.modal {
			box-sizing: boder-box;
			width: 100%;
			max-width: 700px;
			font-size: 15px;
			line-height: 1.73;
			color: var(--dark-indigo);
			padding: 70px 30px 30px 70px;
			border-radius: 20px;
			position: relative;
			h2 {
				font-size: 35px;
				font-weight: 600;
				color: var(--dark-grey-blue);
				margin-top: 0;
			}
			&-text {
				max-width: 550px
			}
			&-btns {
				text-align: right;
				margin-top: 50px;
			}
			.close {
				width: 25px;
				height: 25px;
				border-radius: 6px;
				position: relative;
				background: var(--pale-grey) url('~@/assets/icons/close.svg') center no-repeat;
				position: absolute;
				top: 30px;
				right: 30px;
				cursor: pointer;
				&:hover {
					background-color: var(--powder-blue);
				}
			}
		}
	}
	@media screen and (max-width: 664px){ 
		.modals-wrap .modal {
			margin: 10px;
			padding: 36px 20px 20px 30px;
			h2 {
				font-size: 22px;
				font-weight: 600;
				line-height: 1.27;
				margin-bottom: 10px;
				color: var(--dark-grey-blue);
			}
			p {
				margin: 10px 0;
			}
			.close { 
				top: 20px;
				right: 20px;
			}
		}
	}

</style>