<template>
	<div class="deal" :class="{ 'embedded': embedded }">
		<div class="deal-header" v-if="!embedded">
			<div class="deal-meta">
				<div class="deal-icon">
					<img :src="icon" :alt="icon">
				</div>
				<div class="deal-header-info">
					<div class="deal-date">{{ creationDate }}</div>
					<div class="deal-name">{{ name || 'New deal'}}</div>
				</div>
			</div>

			<router-link
				class="btn deal-edit"
				:to="`/deal/${deal.id}`"
			>
				Open deal
			</router-link>
		</div>

		<p
			class="disclaimer"
			v-if="deal.acceptedSoftQuote"
		>
			You have selected the soft quote proposal!<br/>Our team is now processing this information and we'll get back to you with the next steps as soon as possible
		</p>

		<div class="quotes-list">
			<soft-quote
				v-for="quote in deal.softQuotes"
				:quote="quote"
				:deal="deal"
				:key="quote.DistributionId"
			/>
		</div>
	</div>
</template>

<script>
	import dealStats from '@/mixins/deal-stats.js'

	import SoftQuote from '@/components/SoftQuote'

	export default {
		name: 'QuotedDealPreview',

		components: {
			SoftQuote
		},

		props: {
			deal: {
				type: Object
			},
			embedded: {
				type: Boolean,
				default () {
					return false
				}
			}
		},

		computed: {
			creationDate () {
				const date = new Date(this.deal.created)
				
				const monthNames = [
					"January", "February", "March", "April", "May", "June",
					"July", "August", "September", "October", "November", "December"
				]

				return `${monthNames[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`
			},
		},

		mixins: [
			dealStats
		],
	}
</script>

<style lang="scss" scoped>
	
	.deal {
		border-radius: 16px;
		background-color: #f7f8fc;
		padding: 35px 30px 25px;
		margin: 7.5px;
		width: calc(100% - 20px);
		display: inline-block;
		vertical-align: top;
		box-sizing: border-box;
		min-width: 360px;
		min-height: 260px;
		transition: box-shadow 0.2s ease;
		position: relative;

		&.embedded {
			padding: 0 !important;
		}

		@media screen and (max-width: 400px) {
			padding-left: 25px;
			padding-right: 25px;
		}
	}

	.deal-name {
		font-size: 16px;
		font-weight: 600;
		color: var(--dark-indigo);
		cursor: pointer;
	}

	.deal-icon {
		width: 40px;
		height: 40px;
		object-fit: contain;
		border-radius: 12px;
		box-shadow: 0 2px 14px 0 rgba(71, 71, 71, 0.08);
		background-color: #ffffff;
		margin-right: 20px;
		display: inline-flex;
		align-items: center;
		justify-content: center;

		@media screen and (max-width: 400px) {
			display: none;
		}
	}

	.deal-date {
		font-size: 11px;
		font-weight: 500;
		color: var(--blue-grey);
	}

	.deal-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.deal-meta {
		display: flex;
	}

	.deal-edit {
		padding: 9px 25px;
		font-size: 12px;
	}

	.quotes-list {
		margin-top: 30px;

		@media screen and (max-width: 400px) {
			width: calc(100% + 20px);
			margin-left: -10px;
		}
	}

	.disclaimer {
		background-color: var(--very-light-blue);
		color: var(--dark-indigo);
		padding: 25px;
		border-radius: 20px;
		margin-top: 30px;
	}

	.embedded {
		.disclaimer {
			margin-top: 0;
		}

		.quotes-list:first-child {
			margin-top: 0;
		}
	}

</style>
