<template>
	<div class="questionaire-wrapper">
		<div class="questionaire__sidebar">
			<div class="floating-piece">
				<div class="mobile-left">
					<div class="deal-header">
						<div class="deal-icon"><img :src="icon" :alt="icon"></div>
						<div class="deal-header-info">
							<div class="deal-name">{{ name }}</div>
							<div class="progress-bar-wrap small-only">
								<div class="progress-bar">
								<div class="progress-bar_done" :style="{ 'width': `${roundedProgress}%` }"></div>
								</div>
								<div class="progress-bar-legend">{{ roundedProgress }}%</div>
							</div>
						</div>
					</div>
					<div class="progress-bar-wrap big-only">
						<div class="progress-bar">
						<div class="progress-bar_done" :style="{ 'width': `${roundedProgress}%` }"></div>
						</div>
						<div class="progress-bar-legend">{{ roundedProgress }}%</div>
					</div>
				</div> 
				<router-link to="/">
					<button class="btn btn__dark deal-submit">
						Save <span class="big-only">and</span><span class="small-only">&</span> Exit
					</button>
				</router-link>
				<div class="your-advisor big-only" v-if="showAdvisor">
					<div class="your-advisor__title">Meet your Advisor</div>
					<div class="profile-data">
						<div class="profile-pic">
						<img :src="advisor.pictureUrl" :alt="advisor.firstName">
						</div>
						<div class="profile-info">
						<div class="your-advisor__name">
							{{ advisor.firstName }} {{ advisor.lastName }}
							<!-- <div class="position">Director, Capital Markets</div> -->
						</div>
						<div class="advisor-contact">
							<div class="advisor-contact__email">
							<div class="email-icon"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
								stroke="currentColor" class="w-6 h-6">
								<path stroke-linecap="round" stroke-linejoin="round"
									d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
								</svg>
							</div>
							<a :href="'mailto:' + advisor.email">{{ advisor.email }}</a>
							</div>
							<div class="advisor-contact__phone">
							<div class="phone-icon">
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
								<path stroke-linecap="round" stroke-linejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.380-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
								</svg>
							</div>
							<a :href="'tel:' + advisor.phoneNumber">{{ advisor.phoneNumber }}</a>
							</div>
						</div>
						<div class="">
							<a :href="advisor.calendarLink" target="_blank" class="btn btn__dark btn__small">Schedule a Call →</a>
						</div>
						</div>
					</div>
					</div>
			</div>
		</div>
		<div class="questionaire" :class="{'outstanding-only': outstadingOnly}">
			<div class="questionaire__navigation">
				<div class="left">
					<a
						v-if="deal.softQuotes && deal.softQuotes.length"
						href="#showQuotes"
						@click.prevent="activeTab = 'quotes'"
						:class="{ 'is-active': activeTab == 'quotes' }"
					>
						Soft quotes
					</a>
					<a
						href="#showQuestions"
						@click.prevent="activeTab = 'questions'"
						:class="{ 'is-active': activeTab == 'questions' }"
					>
						Questions
					</a>
					<a
						href="#showDocuments"
						@click.prevent="activeTab = 'documents'"
						:class="{
							'is-active': activeTab == 'documents',
							'is-disabled': documentsList.length < 1
						}"
					>
						Documents
						<div class="docs-left" v-if="documentsLeft">{{ documentsLeft }}</div>
					</a>
					<a
						href="#showDocuments"
						@click.prevent="activeTab = 'call'"
						:class="{
							'is-active': activeTab == 'call',
							'is-hidden': !advisor.calendarLink
						}"
					>
						Schedule a Call
					</a>
				</div>
				<div class="right" v-if="activeTab !== 'call'">
					<div class="switch-wrap" @click="toggleOutstanding()">
						<span class="big-only">Show outstanding items only</span>
						<!-- <span class="small-only">Showing:</span> -->
						<div class="switch-btn">
							<div class="switch big-only" :class="{'is-active': outstadingOnly}"><div class="circle"></div></div>
							<div class="mobile-switch small-only" :class="{'is-active': outstadingOnly}">
								<span v-if="outstadingOnly">Show completed</span>
								<span v-else>Hide completed</span>
							</div>
						</div>
					</div>
				</div>
			</div>

			<template v-if="activeTab == 'questions'">
				<questionnaire-question
					v-for="(question, index) in questionsList"
					:question="question"
					:key="question._id + index"
					:index="index"
					:deal="deal"
					:isCurrent="index == activeQuestion"
					:isLastQuestion="isLastQuestion(question)"
					:data-index="index"
					:estimatedProgress="estimatedProgress"
					:roundedEstimatedProgress="roundedEstimatedProgress"
					@question-saved="updateQuestionsList"
					@active-question-changed="setActiveQuestion"
				/>
			</template>

			<template v-else-if="activeTab == 'quotes'">
				<quoted-deal-preview
					v-if="deal.softQuotes && deal.softQuotes.length"
					:deal="deal"
					embedded
				/>
			</template>

			<template v-else-if="activeTab == 'call'">
				<iframe v-if="advisor.calendarLink" :src="advisor.calendarLink" width="100%" height="1000px" style="border:0; outline:0"></iframe>
			</template>

			<template v-else>
				<questionnaire-documents :deal="deal" :documents="documentsList" @document-updated="handleDocumentUpdate" />
			</template>
		</div>
	</div>
</template>



<script>

	import { mapGetters, mapActions } from 'vuex'
	import mixpanel from '@/helpers/mixpanel.js'

	import QuestionnaireQuestion from '@/components/QuestionnaireQuestion'
	import QuestionnaireDocuments from '@/components/QuestionnaireDocuments'

	import { getFirstQuestion, getNextQuestion } from '@/helpers/questionnaire.js'

	import dealStats from '@/mixins/deal-stats.js'

	import QuotedDealPreview from '@/components/QuotedDealPreview'
	
	export default {
		name: 'Questionnaire',

		components: {
			QuestionnaireQuestion,
			QuestionnaireDocuments,
			QuotedDealPreview
		},

		data () {
			return {
				questionsList: [],
				activeTab: 'questions',
				outstadingOnly: false,
				activeQuestion: -1,
				advisor: {},
				callTabAutoOpened: false
			}
		},

		mixins: [
			dealStats
		],

		props: {
			deal: {
				type: Object,
				required: true
			}
		},

		computed: {
			initialFlow () {
				return this.deal.type == 'Business' ? 'sba-flow' : 'property-flow'
			},

			flows () {
				return this.deal.flows
			},

			...mapGetters([
				'questions',
				'customer',
				'distributions'
			]),

			documentsList () {
				return this.questionsList.filter(question => {
					return question.type == 'document'
				})
			},

			showAdvisor() {
				return this.advisor && Object.keys(this.advisor).length > 0;
			},

			documentsLeft () {
				return this.documentsList.filter(document => {
					return !this.deal.data[document.name]
				}).length
			},

			readyToSubmit () {
				return this.questionsList && this.questionsList.length > 0 && this.isLastQuestion(this.questionsList[this.questionsList.length - 1])
			}
		},

		methods: {
			...mapActions([
				'updateDeal',
				'showModal',
				'checkDistributions',
			]),

			submitDeal () {
				this.showModal({
					name: 'SubmitDeal',
					data: {
						dealId: this.deal.id
					}
				})
			},

			isLastQuestion (question) {
				let schemeItem = this.deal.scheme[question.flow][question._id]
				let next = schemeItem.next || schemeItem.options[0]

				if (!next) return false

				return this.questions[next].type == 'end' && !this.questions[next].nextFlow
			},

			setActiveQuestion (index) {
				this.activeQuestion = index
			},

			toggleOutstanding() {
				this.outstadingOnly = !this.outstadingOnly
				mixpanel.track("Outstanding mode toggled", {
					"Outstanding mode": this.outstadingOnly ? "On" : "Off",
					"Triggered by": "User"
				})
			},

			initQestionsList () {
				if (this.deal.questionsStory.length > 0) {
					this.questionsList = this.deal.questionsStory
				} else {
					const initialQuestion = getFirstQuestion({ dealId: this.deal.id, flow: this.initialFlow })
					
					this.questionsList.push(initialQuestion)
				}

				this.setActiveQuestion(this.questionsList.length - 1)
				
			},

			updateQuestionsList ({ index, id, value }) {
				let nextQuestion = getNextQuestion({
					dealId: this.deal.id,
					currentQuestionId: id, 
					currentElementIndex: index,
					currentValue: value
				})

				let shouldBeSaved = false

				window.analytics.track("Questionnaire step viewed", {
					"step_name": nextQuestion.name,
					"step_question": nextQuestion.subtitle,
					"step_type": nextQuestion.type,
					"step_appearance": nextQuestion.appearance,
					"step_position": index+1,
					"deal_id": this.deal.id
				});

				if (index < this.questionsList.length - 1) {
					if (nextQuestion.name !== this.questionsList[index + 1].name) {
						this.questionsList.length = index + 1
						this.questionsList.push(nextQuestion)
						shouldBeSaved = true
					} else {
						this.scrollToLastQuestion()
					}
				} else {
					this.questionsList.push(nextQuestion)
					shouldBeSaved = true
				}

				if (shouldBeSaved) {
					setTimeout(() => {
						this.saveQuestionsList()
					}, 1000)
				}
			},

			async checkAdvisors() {
				if (Object.keys(this.advisor).length < 1) {
					console.log(this.deal)
					await this.checkDistributions({ dealId: this.deal.id, dealData: this.deal.data });
					console.log(this.distributions)
					if (this.distributions.recommended && 'advisor' in this.distributions) {
						this.advisor = this.distributions.advisor;
						this.advisor.calendarLink += "?via=redirect&email=" + (this.customer?.email ?? '') 
							+ "&firstName=" + (this.customer?.firstName ?? '') 
							+ "&lastName=" + (this.customer?.lastName ?? '') 
							+ "&phone=" + (this.customer?.phone ?? '');

						if (!this.callTabAutoOpened) {
							this.activeTab = 'call';
							this.callTabAutoOpened = true;
							window.analytics.track("Scheduling shown");
						}
					}
				}
			},

			async saveQuestionsList () {				
				try {
					await this.updateDeal({
						id: this.deal.id,
						questionsStory: this.questionsList
					})
					
				} catch(e) {
					console.log(e)
				}
			},

			scrollToLastQuestion () {
				let $question = document.querySelector('#question-'+this.questionsList[this.questionsList.length - 1]._id)
				if ($question) {
					let index = parseInt($question.dataset.index)
					this.setActiveQuestion(index)
					$question.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"})
				}
			},

			handleDocumentUpdate (questionId) {
				const index = this.questionsList.length - 1

				if (questionId == this.questionsList[index]._id) {
					this.updateQuestionsList({
						index,
						id: questionId
					})
				}
			}
		},

		watch: {
			questionsList: {
				handler () {
					this.$nextTick(() => {
						this.scrollToLastQuestion()
					})
				},

				deep: true
			}
		},

		mounted () {
			this.initQestionsList()
			if (this.deal.softQuotes && this.deal.softQuotes.length) {
				this.activeTab = 'quotes'
			}
			if (window && window.location.search.includes('outstanding')) {
				this.outstadingOnly = true;
				mixpanel.track("Outstanding mode toggled", {
					"Outstanding mode": "On",
					"Triggered by": "URL"
				})
			}
			this.checkAdvisors(this.deal.id);
		}
	}
</script>

<style lang='scss' scoped>
	.questionaire {
		display: flex;
		align-items: center;
		flex-direction: column;
		padding: 0 0 0 50px;
		width: 100%;
		max-width: 1100px;
		padding-bottom: 28vh;

		&-wrapper {
			display: flex;
			flex-direction: row;
			justify-content: center;
			padding: 35px 30px;
		}

		&__sidebar {
			flex: 0 0 218px;
		}

		&__navigation {
			width: 100%;
			text-align: left;
			/* padding-top: 35px; */
			margin-bottom: 60px;
			border-bottom: 1px solid var(--very-light-blue);
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			position: -webkit-sticky;
			position: sticky;
			top: 68px;
			background: var(--pale-grey);
			z-index: 9;
			padding-top: 30px;
			margin-top: -30px;
			&:after {
				left:0;
				top: 100%;
				position: absolute;
				width: 100%;
				margin-top: 1px;
				pointer-events:none;
				height: 67px;
				content:"";
				display:block;	
				background-image: linear-gradient(to top, rgba(252, 252, 254, 0), #f7f8fc);
			}

			.left {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: center;
			}

			.switch-wrap {
				display: flex; 
				align-items: center;
				justify-content: space-between;
				padding-bottom: 9px;
				span {
					font-size: 13px;
					font-weight: 500;
					color: var(--dark-grey-blue);
				}	
				.switch {
					height: 24px;
					width: 42px;
					margin-left: 13px;
					border-radius: 12px;
					background-color: var(--light-grey-blue);
					cursor: pointer;
					transition: background-color 0.3s;
					/* display: flex; */
					.circle {
						width: 16px;
						height: 16px;
						background-color: white;
						display: inline-block;
						border-radius: 50%;
						transform: translateY(2px) translateX(5px);
						pointer-events: none;
						transition: transform 0.15s;
					}
					&.is-active {
						background-color: var(--dull-orange);
						.circle {
							transform: translateY(2px) translateX(21px);
						}
					}  
				}
			}
			.mobile-switch {
				span {
					font-size: 13px;
					font-weight: 500;
					color: var(--orange);
					margin-left: 3px;
					border-bottom: 1px solid;
					display: inline-block;
					line-height: 1.1;	
					vertical-align: middle;
				}
			}

			a {
				font-weight: 500;
				font-size: 13px;
				color: var(--blue-grey);
				display: block;
				padding-bottom: 10px;
				margin-right: 20px;
				position: relative;
				&:hover {
					color: var(--dark-grey-blue);
				}

				&.is-disabled {
					color: var(--powder-blue);
					pointer-events: none;
				}

				&.is-active {
					color: var(--dark-grey-blue);

					&:after {
						position: absolute;
						top: 100%;
						left: 0;
						width: 100%;
						height: 1px;
						display: block;
						content: "";
						background-color: var(--dark-grey-blue);
					}
				}
				.docs-left {
					border-radius: 5px;
					background-color: var(--vermillion);
					font-size: 10px;
					font-weight: 600;
					color: #ffffff;
					content: data(data-docsleft);
					display: inline-block;
					width: 17px;
					text-align: center;
					height:17px;
					line-height: 17px;
					padding-right: 1px; /* alignment fix*/
					margin-left: 3px;
				}
			}
		}
	}

	.deal-header {
		display: flex;
		align-items: center;
	}
	.deal-icon {
		width: 40px;
		height: 40px;
		object-fit: contain;
		border-radius: 12px;
		box-shadow: 0 2px 14px 0 rgba(71, 71, 71, 0.08);
		background-color: #ffffff;
		margin-right: 20px;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		flex: 0 0 40px;
	}
	.outstanding-only {
		.q:not(.is-previous.is-skipped):not(.is-active){
			display: none !important;
		}
	}
	.deal-name {
		font-size: 16px;
		font-weight: 600;
		color: var(--dark-indigo);
		line-height: 1.23;
	}
	.deal-submit {
		width: 100%;
		margin-top: 20px;
	}
	.advisor-contact {
		font-size:14px;
	}
	.floating-piece {
		position: -webkit-sticky; /* Safari */
		position: sticky;
		top: 98px;
	}
	
	.your-advisor {
		margin-top: 20px;
		background-color: white;
		padding:15px 20px;
		border-radius: 12px;
		font-size: 16px;
		box-shadow: 0 2px 14px 0 rgba(71, 71, 71, 0.08);
		border: solid 1px #e6e6e6;
		
		&__title {
			font-weight: 600;
			color: var(--dark-grey-blue);
			font-size: 16px;
			margin-bottom: 10px;
		}
		&__name {
			font-weight: 500;
			color: var(--dark-grey-blue);
			margin-top: 10px;
		}
		a {
			color: var(--blue-grey);
			font-weight: 500;
		}
		.btn {
			color: white;
			width: 100%;
			box-sizing: border-box;
			text-align: center;
			// margin-top: 10px;
		}
		.profile-pic {
			width:80px;
			height:80px;
			object-fit: contain;
			border-radius: 12px;
			background-color: #ffffff;
			margin-right: 10px;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			overflow: hidden;
			// flex: 0 0 40px;
			img {
				max-width: 100%;
			}
		}
		.position {
			font-size: 14px;
			font-weight: 500;
			color: var(--blue-grey);
			margin-bottom: 10px;
		}
		.email-icon {
			width: 16px;
			height: 16px;
			object-fit: contain;
			margin-right: 5px;
			color: var(--blue-grey);
		}
		.phone-icon {
			width: 16px;
			height: 16px;
			object-fit: contain;
			margin-right: 5px;
			color: var(--blue-grey);
		}
		
		.advisor-contact__email, .advisor-contact__phone {
			display: flex;
			align-items: center;
			margin-bottom: 10px;
		}
		.advisor-info {
			display: flex;
			align-items: center;
		}
		.profile-data {
			display: flex;
			flex-direction: column;
		}
	}
	.is-hidden {
		display: none !important;
	}

	@media screen and (max-width: 664px){ 
		.questionaire {
			padding: 0 17px;
			&__navigation {
				margin-bottom: 25px;
				top: 70px;
				z-index: 97;
				padding-top: 12px;
				margin-top: -12px;
				&:after {
					height: 30px;
				}
			}
			&-wrapper {
				padding-top: 25px;
				padding-right: 0;
				padding-left: 0;
			}
		}
		.questionaire__sidebar {
			position: fixed;
			top: 0px;
			flex: none;
			width: 100%;
			left: 0;
			background: white;
			z-index: 99;
		}
		.floating-piece {
			position: static;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			padding: 15px 20px;
			box-shadow: 0 3px 25px 20px rgba(174, 185, 193, 0.18);
			.mobile-left {
				flex-grow: 1;
				& + a {
					flex: 0 0 100px;
				}
			}
			.btn {
				padding: 9px 15px;
				border-radius: 12px;
				width: auto;
				font-size: 12px;
				margin-top:0;
				flex: 0 0 129px;
			}
		}
		.progress-bar-wrap {
			margin-top: 0;
		}
		.progress-bar-legend {
			flex: 0 0 30px;
		}
		.deal-name {
			font-size: 14px;
		}
		.deal-icon {
			margin-right: 15px;
		}
		.deal-header-info {
			width: 100%;
			padding-right: 20px;
		}
		.deal-name {
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
		}
		.mobile-left, .deal-header-info {
			min-width: 0;
		}
	}
	@media screen and (max-height: 860px){ 
		.questionaire {
			padding-bottom: 0;
		}
	}
	@media screen and (min-width: 665px) and (max-height: 600px){ 
		.questionaire-wrapper {
			padding-bottom: 0;
		}
	}
</style>