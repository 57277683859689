<template>
	
	<div class="modal-text">
		<h2>Are you sure you want to accept this quote?</h2>
		<p>Some text about what will happen next</p>
	</div>

	<div class="modal-btns">
		<button type="button" class="btn btn__primary">Yes</button>
		<button type="button" class="btn btn__secondary">No</button>
	</div>
	
</template>



<script>

	import { mapActions } from 'vuex'
	
	export default {
		name: 'ModalAcceptQuote',

		data () {
			return {
				isLoading: false
			}
		},

		emits: ['closed'],

		props: {
			modalData: {
				type: Object
			}
		},

		methods: {
			...mapActions([
				'notify'
			]),

			closeModal () {
				this.$emit('closed')
			}
		}
	}
</script>



<style lang="scss">
	
	.modal {
		width: 400px;
		background: white;
		padding: 40px;
		box-sizing: border-box;
	}

	.btn + .btn {
		margin-left: 16px;
	}

</style>