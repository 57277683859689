<template>
	<div v-if="deal">
		<questionnaire v-if="deal(id)" :deal="deal(id)" />
	</div>
</template>



<script>
	
	import { mapGetters, mapActions } from 'vuex'

	import Questionnaire from '@/components/Questionnaire'

	export default {
		name: 'PageDeal',

		components: {
			Questionnaire
		},

		computed: {
			...mapGetters([
				'deal'
			]),

			id () {
				return this.$route.params.id
			}
		},

		methods: {
			...mapActions([
				'getDeal'
			])
		},

		created () {
			this.getDeal(this.id)
		}
	}

</script>