<template>
	
	<div class="modal-text welcome-modal">
		<img src="@/assets/loader.gif" alt="Janover">
		<h2>One moment, please …</h2>
		<p><!--John, p @seva-->Please wait one moment while we create your account.<br>You will be able to complete your<!-- Multifamily refinance @seva--> loan package in our secure portal.</p>
	</div>
	
</template>



<script>
	
	export default {
		name: 'ModalWelcome',

		methods: {
			closeModal () {
				this.$emit('closed')
			}
		},

		mounted () {
			setTimeout(() => {
				this.closeModal()
			}, 5000)
		}
	}
</script>



<style lang="scss">
	
	.modal {
		width: 400px;
		background: white;
		padding: 40px;
		box-sizing: border-box;
	}
	.welcome-modal {
		text-align: center;
		img {
			width: 70px;
		}
	}
</style>