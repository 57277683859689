<template>
	<div
		v-if="quote"
		class="quote"
		:class="{
			'submitted': deal.acceptedSoftQuote && deal.acceptedSoftQuote == quote.DistributionId,
			'muted': deal.acceptedSoftQuote && deal.acceptedSoftQuote !== quote.DistributionId
		}"
	>
		<div
			class="flex items-center justify-between border-b quote-head"
		>
			<div class="flex items-center">
				<img
					:src="quote.Originator.logo"
					v-if="quote.Originator.logo"
					class="max-h-8 mr-2"
				>
				<div>
					<span class="text-md font-bold">{{ quote.Originator.lender }}</span>
					<div class="text-xs text-gray-500">by {{ quote.Originator.name }}</div>
				</div>
			</div>
		</div>

		<div class="space-y-2 py-4 px-6">
			<div class="flex mb-3 space-x-1 items-center lg:text-2xl">
				<span class="font-extrabold">
					{{ quote.TermYears ? `${quote.TermYears} years at` : '' }} {{ quote.Rate.StartRate }}% {{ quote.Rate.Type }}
				</span>
			</div>

			<div
				class="flex items-center justify-between"
				v-if="quote.LoanAmount"
			>
				<div class="text-sm text-gray-500 ">
					<span class="font-bold text-gray-800">${{ quote.LoanAmount }}</span> loan amount
				</div>
				<div class="text-sm "></div>
			</div>
			
			<div
				class="flex items-center justify-start space-x-3"
				v-if="quote.MaxLTV || quote.MaxLTC"
			>
				<div class="text-sm text-gray-500" v-if="quote.MaxLTV">
					<span class="font-bold text-gray-800">{{ quote.MaxLTV }}%</span> LTV
				</div>
				<div class="text-sm text-gray-500" v-if="quote.MaxLTC">
					<span class="font-bold text-gray-800">{{ quote.MaxLTC }}%</span> LTC
				</div>
			</div>
			
			<div
				class="flex items-center justify-between"
				v-if="quote.Amortization"
			>
				<div class="text-sm text-gray-500">
					<span class="font-bold text-gray-800">{{ quote.Amortization }} years</span> amortization
				</div>
				<div class="text-sm "></div>
			</div>
			
			<div
				class="flex items-center justify-between"
				v-if="quote.Rate && quote.Rate.Index && quote.Rate.Index.Description"
			>
				<div class="text-sm text-gray-500">
					<span class="font-bold text-gray-800">{{ quote.Rate.Index.Description }}</span> indexed
				</div>
				<div class="text-sm "></div>
			</div>
			
			<div
				class="flex items-center justify-between"
				v-if="quote.InterestOnly"
			>
				<div class="text-sm text-gray-500 ">
					<span class="font-bold text-gray-800">
					{{ quote.InterestOnly.Period }} {{ quote.InterestOnly.Details }}
					</span> interest-only
				</div>
			</div>

			<div
				class="flex items-center justify-between"
				v-if="quote.Dscr"
			>
				<div class="text-sm text-gray-500 ">
					<span class="font-bold text-gray-800">
					{{ quote.Dscr }}
					</span> Minimum DSCR
				</div>
			</div>
			
			<div
				class="flex items-center justify-between"
				v-if="quote.OriginationFee"
			>
				<div class="text-sm text-gray-500">
					<span class="font-bold text-gray-800">
					{{ quote.OriginationFee }}% ~ ${{ (quote.OriginationFee / 100) * quote.LoanAmount }}
					</span> origination fee
				</div>
			</div> 

			<div
				class="pt-2 flex flex-column items-start space-y-2"
				v-if="quote.Rate?.Type || quote.Recourse || quote.PrepaymentPenalty"
			>
				<template v-if="quote.Rate && quote.Rate.Type">
					<div
						class="text-xs font-medium rounded-md px-2 py-1"
						:class="{
							'text-green-700 bg-green-100': quote.Rate.Type == 'Fixed',
							'text-yellow-700 bg-yellow-100': quote.Rate.Type !== 'Fixed'
						}"
					>
						{{ quote.Rate.Type == 'Fixed' ? 'Fixed at signing' : 'Not fixed at signing' }}
					</div>
				</template>

				<template v-if="quote.Recourse">
					<div
						class="text-xs font-medium rounded-md px-2 py-1"
						:class="{
							'text-green-700 bg-green-100': quote.Recourse.Type.Id == 'Full',
							'text-yellow-700 bg-yellow-100': quote.Recourse.Type.Id !== 'Full'
						}"
					>
						{{ quote.Recourse.Type.Description }} Recourse

						<div
							class="text-xs text-gray-500"
							v-if="quote.Recourse.Details"
						>
							- {{ quote.Recourse.Details }}
						</div>
					</div>
				</template>

				<template v-if="quote.PrepaymentPenalty">
					<div
						class="text-xs font-medium rounded-md px-2 py-1"
						:class="{
							'text-green-700 bg-green-100': !quote.PrepaymentPenalty.Details,
							'text-yellow-700 bg-yellow-100': quote.PrepaymentPenalty.Details
						}"
					>
						{{ quote.PrepaymentPenalty.Details ? 'Prepayment Penalties' : 'No prepayment penalty' }}

						<div
							class="text-xs text-gray-500"
							v-if="quote.PrepaymentPenalty.Details"
						>
							- {{ quote.PrepaymentPenalty.Details }}
						</div>
					</div>
				</template>
			</div>
		</div>

		<div
			class="flex items-center justify-center border-t quote-footer"
			v-if="!deal.acceptedSoftQuote || deal.acceptedSoftQuote == quote.DistributionId"
		>
			<div
				class="icon-accepted"
				v-if="deal.acceptedSoftQuote && deal.acceptedSoftQuote == quote.DistributionId"
			/>
			<button
				class="btn btn__primary"
				v-else
				@click="handleAcceptQuote(quote.DistributionId)"
			>
				Accept terms
			</button>
		</div>
	</div>
</template>



<script>

	import { mapActions } from 'vuex'

	export default {
		name: 'SoftQuotePreview',

		props: {
			quote: {
				type: Object,
				required: true
			},

			deal: {
				type: Object,
				required: true
			},

			accepted: {
				type: Boolean,
				default: false
			}
		},

		data () {
			return {
				distribution: false
			}
		},

		computed: {
			creationDate () {
				const date = new Date()
				
				const monthNames = [
					"January", "February", "March", "April", "May", "June",
					"July", "August", "September", "October", "November", "December"
				]

				return `${monthNames[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`
			}
		},

		methods: {
			...mapActions([
				'updateDeal'
			]),

			async handleAcceptQuote (distributionId) {
				console.log(distributionId)
				try {
					await this.updateDeal({
						id: this.deal._id,
						acceptedSoftQuote: distributionId
					})
				} catch(e) {
					console.log(e)
				}	
			}
		}
	}
</script>



<style lang="scss" scoped>
	
	.quote {
		border-radius: 16px;
		background-color: white;
		padding: 25px 30px;
		width: calc(33% - 12px);
		display: inline-block;
		vertical-align: top;
		box-sizing: border-box;
		min-height: 260px;
		position: relative;
		margin-right: 22px;

		&.muted {
			opacity: 0.7;
			pointer-events: none;
		}

		&.submitted {
			background-color: var(--dark-grey-blue);
			color: var(--very-light-blue);

			.dashboard-deals-num {
				background-color: var(--very-light-blue);
				color: var(--dark-grey-blue);
			}
			.deal-data-icon {
				filter: brightness(300%) saturate(0);
			}
			.deal-name {
				color: #fff;
			}
			.deal-data-name {
				color: var(--very-light-blue);
			}
			.accent {
				color: white !important;
			}
			.deal-icon {
				position: relative;
				&:after {
					position: absolute;
					content:"✔︎"; /* @seva need url from assets */
					font-size: 10px;
					line-height: 20px;
					text-align: center;
					color: white;
					width: 19px;
					height: 19px;
					top: -8px;
					right: -8px;
					border-radius: 10px;
					background-color: var(--asparagus);
				}
			}

			.text-gray-500 {
				color: var(--very-light-blue);
			}

			.font-bold {
				color: white;
			}
		}

		@media screen and (min-width: 1040px) {
			width: calc(33% - 12px);

			&:nth-child(3n+3) {
				margin-right: 0;
			}

			&:nth-child(n+4) {
				margin-top: 22px;
			}	
		}

		@media screen and (min-width: 700px) and (max-width: 1039px) {
			width: calc(50% - 12px);

			&:nth-child(2n+2) {
				margin-right: 0;
			}

			&:nth-child(n+3) {
				margin-top: 22px;
			}	
		}

		@media screen and (max-width: 699px) {
			width: 100%;

			& + & {
				margin-top: 22px;
			}
		}
		
	}

	.quote-head {
		width: calc(100% + 60px);
		padding: 0 30px 25px;
		margin-left: -30px;
		box-sizing: border-box;
		margin-bottom: 20px;
	}

	.quote-footer {
		width: calc(100% + 60px);
		padding: 25px 30px 0;
		margin-left: -30px;
		box-sizing: border-box;
		margin-top: 20px;

		.btn {
			font-size: 12px;
		}
	}

	.flex {
		display: flex;
	}

	.flex-wrap {
		flex-wrap: wrap;
	}

	.flex-column {
		flex-direction: column;
	}

	.justify-center {
		justify-content: center;
	}

	.items-center {
		align-items: center;
	}

	.justify-between {
		justify-content: space-between;
	}

	.justify-start {
		justify-content: flex-start;
	}

	.items-start {
		align-items: flex-start;
	}

	.font-extrabold {
		font-weight: 700;
		font-size: 18px;
	}

	.font-medium {
		font-weight: 500;
	}

	.font-bold {
		font-weight: 600;
		margin-right: 4px;
	}

	.justify-start {
		justify-content: flex-start;
	}

	.text-sm {
		font-size: 14px;
	}

	.text-xs {
		font-size: 12px;
	}

	.text-gray-500 {
		color: gray;
	}

	.text-gray-800 {
		color: black;
	}

	.mb-3 {
		margin-bottom: 16px;
	}

	.space-x-1 {
		& > * + * {
			margin-left: 10px;
		}
	}

	.space-x-2 {
		& > *:not(:last-child) {
			margin-right: 16px;
		}
	}

	.space-x-3 {
		& > * + * {
			margin-left: 16px;
		}
	}

	.pt-2 {
		padding-top: 8px;
	}

	.px-2 {
		padding-left: 16px;
		padding-right: 16px;
	}

	.py-1 {
		padding-top: 8px;
		padding-bottom: 8px;
	}

	.space-y-2 {
		& > * + * {
			margin-top: 8px;
		}
	}

	.text-green-700 {
		color: rgba(4,120,87,1);
	}

	.bg-green-100 {
		background-color: rgba(209,250,229,1);
	}

	.text-yellow-700 {
		color: rgba(180,83,9,1);
	}

	.bg-yellow-100 {
		background-color: rgba(254,243,199,1);
	}

	.rounded-md {
		border-radius: 8px;
	}

	.max-h-8 {
		max-height: 40px;
	}

	.mr-2 {
		margin-right: 8px;
	}

	.border-b {
		border-bottom: 1px solid var(--powder-blue);
	}

	.border-t {
		border-top: 1px solid var(--powder-blue);
	}

	.icon-accepted {
		font-size: 16px;
		line-height: 32px;
		text-align: center;
		color: white;
		width: 32px;
		height: 32px;
		border-radius: 100%;
		background-color: var(--asparagus);

		&:before {
			content: "✔︎";
		}
	}
</style>
