<template>
  <form novalidate @submit.prevent="handleFormSubmit">
    <h3>{{ title }}</h3>
    <div class="form-input-wrap" :class="{'form-date': title.includes('Date')}">
      <div
        v-for="({ type, options, label, placeholder, model, editable}, key) in config.fields"
        :key="key"
      >
        <label>
          <span>{{ label }}</span>
          
          <div class="select-wrap" v-if="type == 'select'">
            <select
              :placeholder="placeholder"
              :disabled="!editable"
              v-model="formFields[model]"
              @change="wasChanged = true"
              :class="{ 'muted': !formFields[model] }"
            >
              <option value="" :selected="true" disabled v-if="!formFields[model]">...</option>
              <option v-for="option in options" :key="`${key}-${option}`">{{ option }}</option>
            </select>
          </div>

          <input
            v-else
            :type="type"
            :placeholder="placeholder"
            :disabled="!editable"
            v-model="formFields[model]"
            :class="{ 'invalid': !validationState[model] }"
            :ref="model"
            :name="model"
            @input="wasChanged = true"
          >
        </label>
      </div>
    </div>
    <div class="buttons-wrap" v-if="wasChanged">
      <button class="btn  btn__secondary" type="cancel" @click="handleCancel" :disabled="isLoading">Cancel</button>
      <button class="btn  btn__primary" type="submit" :disabled="isLoading || !formIsValid">Save changes</button>
    </div>
  </form>
</template>



<script>

  import { mapActions, mapGetters } from 'vuex'
  import Inputmask from 'inputmask'
  import validations from '@/helpers/validations.js'
  
  export default {

    name: 'ProfileGenericForm',

    props: {
      config: {
        type: Object,
        required: true
      },

      title: {
        type: String,
        required: true
      }
    },

    computed: {
      ...mapGetters([
        'customer'
      ]),

      validationState () {
        const state = {}

        if (Object.values(this.formFields).length) {
          for (let key in this.formFields) {
            state[key] = true

            if (this.config.fields[key].validations && this.config.fields[key].validations.length > 0) {
              this.config.fields[key].validations.forEach(validation => {
                if (!validations[validation](this.formFields[key])) state[key] = false
              })
            }
          }
        }

        return state
      },

      formIsValid () {
        return Object.values(this.validationState).filter(value => !value).length < 1
      }
    },

    data () {
      return {
        isLoading: false,
        formFields: {},
        wasChanged: false,
        maskInstances: {}
      }
    },

    methods: {
      ...mapActions([
        'updateCustomer',
        'notify'
      ]),

      initForm () {
        // weird flex but ok
        //

        const category = this.config.category

        for (let key in this.config.fields) {
          const { model } = this.config.fields[key]

          if (category) {
            this.formFields[model] = this.customer[category][model]
          } else {
            this.formFields[model] = this.customer[model]
          }
        }
      },

      handleCancel () {
        this.initForm()
        this.wasChanged = false
      },

      async handleFormSubmit () {
        try {
          if (!this.isLoading) {
            this.isLoading = true
            let payload = {}

            if (this.config.category) payload[this.config.category] = this.formFields
            else payload = this.formFields

            await this.updateCustomer(payload)
            this.isLoading = false
            this.wasChanged = false
            this.notify({
              message: `${this.title} was successfuly updated`
            })
          }
        } catch (e) {
          console.log(e)
          this.notify({
            type: 'error',
            message: e
          })
          this.isLoading = false
        }
      }
    },

    watch: {
      formFields: {
        handler () {
          if (this.formFields.phone && this.formFields.phone == '(1__) ___-____') this.formFields.phone = '(___) ___-____'
        },
        deep: true
      }
    },

    created () {
      this.initForm()
    },

    mounted () {
      Object.values(this.config.fields).forEach(field => {
        if (field.mask) {
          this.maskInstances[field.model] = new Inputmask({ mask: field.mask })
          this.maskInstances[field.model].mask(this.$refs[field.model])
        }
      })
    }

  }

</script>



<style lang="scss" scoped>

  .buttons-wrap {
    padding-top: 45px;
  }
  
  label span {
    display: block;
    font-size: 13px;
    color: var(--dark-grey-blue); 
  }
  h3 {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.22;
    color: var(--dark-grey-blue);
    margin-bottom: 35px;
  }
  input[type=text], input[type="email"] {
    font-weight: normal;
    padding-left: 25px;
    width: 100%;
    max-width: 490px;
    box-sizing: border-box;
    transition: border-color .3s ease-in-out;

    &.invalid {
      border-color: var(--rusty-red);
    }
  }
  label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 15px 40px 15px 0;
  }

  .btn {
		display: block;
		margin-left: auto;
	}

  
  .select-wrap {
    position: relative;
    margin-top: 15px;
    &:after {
      content: "";
      width: 25px;
      height: 25px;
      border-radius: 6px;
      /* background-color: var(--very-light-blue); */
      position: absolute;
      background: var(--very-light-blue) url('~@/assets/icons/select-arrow.svg') center no-repeat;
      top: 50%;
      margin-top: -12px;
      right: 15px;
      display: block;
      pointer-events: none;
    }
    &:hover {
      &:after {
        background: var(--powder-blue) url('~@/assets/icons/select-arrow.svg') center no-repeat;
      }
    }
    select.muted {
      color: var(--light-grey-blue);
    }
  }
  @media screen and (max-width: 664px){
    h3 {
      text-align: center;
      font-size: 16px;
    }
    label {
      display: block;
      margin-left: 0;
      margin-right: 0;
      span {
        margin-bottom: 6px;
      }
    }
    input {
      padding: 15px 20px !important;
      font-size: 14px;
    }
  }

</style>
