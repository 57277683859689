import api from '@/services/api'
import logger from '@/helpers/logger'

export default {
	namespaced: false,

	state: () => {
		return {
			deals: [],
			questions: null,
		}
	},

	getters: {
		dealIds (state, getters, rootState) {
			return rootState.customer.deals
		},

		deals (state) {
			return state.deals
		},

		deal (state) {
			return (id) => {
				return state.deals.find(deal => deal.id == id)
			}
		},

		questions (state) {
			let questionsAsObject = {}
			
			state.questions.forEach((element) => {
				questionsAsObject[element._id] = element				
			})

			return questionsAsObject
		}
	},

	actions: {
		async createDeal ({ dispatch }, type) {
			const payload =  {
				type
			}

			const deal = await api.createDeal(payload)

			await dispatch('getCustomer')
			
			await dispatch('getDeal', deal._id)
			
			return deal
		},

		async getDeal ({ dispatch, commit }, id) {
			logger('ACTION: getDeal')

			await dispatch('getQuestions')

			const deal = await api.getDeal(id)

			commit('updateDeal', deal)

			return deal
		},

		async getDeals ({ getters, dispatch, commit }) {
			logger('ACTION: getDeals')
			

			await dispatch('getQuestions')

			return new Promise((resolve) => {
				Promise.all(getters.dealIds.map(dealId => api.getDeal(dealId))).then((deals) => {
					commit('updateDeals', deals)
					resolve()
				})
			})
		},

		async getQuestions ({ commit }) {
			const questions = await api.getQuestions()

			commit('updateQuestions', questions)
		},

		async updateDeal ({ dispatch }, { id, data, questionsStory, submitted, estimatedProgress, archived, acceptedSoftQuote }) {
			logger('ACTION: updateDeal', data, questionsStory, acceptedSoftQuote)

			const deal = await api.updateDeal(id, {
				data,
				questionsStory,
				submitted,
				estimatedProgress,
				archived,
				acceptedSoftQuote
			})

			await dispatch('getDeal', id)

			return !!deal
		}
	},

	mutations: {

		updateDeal (state, deal) {
			const index = state.deals.findIndex(d => d.id === deal.id)
			if (index !== -1) {
				state.deals.splice(index, 1, deal)
			} else {
				state.deals.push(deal)
			}
		},

		updateDeals (state, deals) {
			logger('MUTATION: updateDeals')

			state.deals = deals
		},

		updateQuestions (state, questions) {
			logger('MUTATION: updateQuestions')

			console.log(questions)
			if (!state.questions) state.questions = questions
		}
	}
}