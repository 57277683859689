import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import VueInputMask from 'vue-inputmask';

const app = createApp(App);

Sentry.init({
  app,
  dsn: 'https://1a59f4c8b6904473802a0a489e231690@o666436.ingest.sentry.io/5766378',
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ['localhost', 'portal.janover.ventures', /^\//],
    }),
  ],
  tracesSampleRate: 1.0,
  logErrors: true,
});

app
  .use(store)
  .use(router)
  .use(VueInputMask)
  .mount('#app');