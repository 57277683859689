<template>
	<div
		v-if="deal"
		class="deal"
		:class="{
			'submitted': deal.submitted,
			'closed': isClosed,
			'archived': deal.archived
		}"
		@click="handleClick"
	>
		<div class="deal-header">
			<div class="deal-icon">
				<img :src="icon" :alt="icon">
			</div>
			<div class="deal-header-info">
				<div class="deal-date">{{ creationDate }}</div>
				<div class="deal-name">{{ name || 'New deal'}}</div>
			</div>
		</div>

		<div class="progress-bar-wrap">
			<div class="progress-bar">
				<div class="progress-bar_done" :style="{ 'width': `${progress}%` }"></div>
			</div>
			<div class="progress-bar-legend">{{ parseInt(progress) }}%</div>
		</div> 

		<div class="deal-data">
			<div class="deal-data-item">
				<div class="deal-data-icon"><img src="@/assets/icons/question-small-icon-dark.svg" alt="Questions"></div>
				<div class="deal-data-name">Questions answered</div>
				<div class="deal-data-progress">
					<div class="accent">{{ questionsAnswered.length }}</div> / {{ estimatedQuestionsList.length }}
				</div>
			</div>
			<div class="deal-data-item">
				<div class="deal-data-icon"><img src="@/assets/icons/document-small-icon-dark.svg" alt="Questions"></div>
				<div class="deal-data-name">Documents uploaded</div>
				<div class="deal-data-progress"><div class="accent">{{ documentsUploaded }}</div> / {{ estimatedDocumentsList.length }}</div>
			</div>
		</div>

		<!-- <div class="deal-address" v-if="address">
			<p>{{ address }}</p>
		</div> -->

		<div class="deal__buttons" v-if="canEdit">
			<button class="btn btn__secondary deal-archive" @click.stop="archiveDeal">Archive</button>
			<button class="btn deal-edit">Edit</button>
		</div>

		<div class="deal__overlay" v-if="deal.archived && !isClosed">
			<button class="btn btn__secondary" @click.stop="unarchiveDeal">Unarchive</button>
		</div>
	</div>
</template>



<script>

	import { mapGetters, mapActions } from 'vuex'

	import dealStats from '@/mixins/deal-stats.js'
	
	export default {
		name: 'DealPreview',

		props: {
			deal: {
				type: Object,
				required: true
			}
		},

		mixins: [
			dealStats
		],

		computed: {
			...mapGetters([
				'questions'
			]),

			creationDate () {
				const date = new Date(this.deal.created)
				
				const monthNames = [
					"January", "February", "March", "April", "May", "June",
					"July", "August", "September", "October", "November", "December"
				]

				return `${monthNames[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`
			},

			isClosed () {
				return this.deal.status == 'closed' || this.deal.status == 'closed won'
			},

			canEdit () {
				return !this.deal.archived && !this.isClosed
			},

			address () {
				if (!this.deal.data['Property address']) return false

				if (typeof this.deal.data['Property address'] == 'object') {
					let { country, administrative_area_level_1, administrative_area_level_2, locality, street_number, postal_code, route } = this.deal.data['Property address']

					return `${street_number || ''} ${route}, ${locality}, ${administrative_area_level_2 || ''}, ${administrative_area_level_1} ${postal_code}, ${country}`
				} else return this.deal.data['Property address']
			}
		},

		methods: {
			...mapActions([
				'updateDeal',
				'notify'
			]),

			handleClick () {
				if (!this.isClosed && !this.deal.archived) this.$router.push(`/deal/${this.deal.id}`)
			},

			async archiveDeal () {
				try {
					await this.updateDeal({
						id: this.deal._id,
						archived: true
					})

					this.notify({
						message: 'Your deal was added to archive'
					})
				} catch(e) {
					this.notify({
						type: 'error',
						message: e.error || e.message || e
					})
				}
			},

			async unarchiveDeal () {
				try {
					await this.updateDeal({
						id: this.deal._id,
						archived: false
					})

					this.notify({
						message: 'Your deal was removed from archive'
					})
				} catch(e) {
					this.notify({
						type: 'error',
						message: e.error || e.message || e
					})
				}
			}
		}
	}
</script>



<style lang="scss" scoped>
	
	.deal {
		border-radius: 16px;
		background-color: #f7f8fc;
		padding: 35px 30px 25px;
		margin: 7.5px;
		width: calc(33% - 11px);
		display: inline-block;
		vertical-align: top;
		box-sizing: border-box;
		min-width: 360px;
		min-height: 260px;
		transition: box-shadow 0.2s ease;
		position: relative;

		&.closed {
			opacity: 0.8;
			pointer-events: none;
		}
		
		&:not(.closed):not(.archived) {
			cursor: pointer;
			
			&:hover {
				box-shadow: 0 16px 42px -16px rgba(52, 79, 99, 0.23);
			}
		}

		&.submitted {
			background-color: var(--dark-grey-blue);

			.dashboard-deals-num {
				background-color: var(--very-light-blue);
				color: var(--dark-grey-blue);
			}
			.deal-data-icon {
				filter: brightness(300%) saturate(0);
			}
			.deal-name {
				color: #fff;
			}
			.deal-data-name {
				color: var(--very-light-blue);
			}
			.accent {
				color: white !important;
			}
			.deal-icon {
				position: relative;
				&:after {
					position: absolute;
					content:"✔︎"; /* @seva need url from assets */
					font-size: 10px;
					line-height: 20px;
					text-align: center;
					color: white;
					width: 19px;
					height: 19px;
					top: -8px;
					right: -8px;
					border-radius: 10px;
					background-color: var(--asparagus);
				}
			}
		}
	}

	.deal-name {
		font-size: 16px;
		font-weight: 600;
		color: var(--dark-indigo);
		cursor: pointer;
	}

	.deal-icon {
		width: 40px;
		height: 40px;
		object-fit: contain;
		border-radius: 12px;
		box-shadow: 0 2px 14px 0 rgba(71, 71, 71, 0.08);
		background-color: #ffffff;
		margin-right: 20px;
		display: inline-flex;
		align-items: center;
		justify-content: center;
	}

	.deal-date {
		font-size: 11px;
		font-weight: 500;
		color: var(--blue-grey);
	}

	.deal-header {
		display: flex;
	}
	
	.deal-archive,
	.deal-edit {
		padding: 9px 25px;
		font-size: 12px;
	}

	.deal-data {
		margin-top: 20px;
		&-name {
			font-size: 13px;
			color: var(--dark-grey-blue);
			flex: auto;
			padding-left: 10px;
		}
		&-item {
			display: flex;
			justify-content: space-between;
			margin-top: 3px;
		}
		&-progress {
			font-size: 13px;
			font-weight: 500;
			color: var(--blue-grey);
			.accent {
				color: var(--dark-indigo);
				display: inline;
			}
		}
	}

	.deal.submitted {
		background-color: var(--dark-grey-blue);
		min-height: 200px;

		.deal-data-icon {
			filter: brightness(300%) saturate(0);
		}
		
		.deal-name {
			color: #fff;
		}

		.deal-data-name {
			color: var(--very-light-blue);
		}

		.accent {
			color: white !important;
		}

		.deal-icon {
			position: relative;

			&:after {
				position: absolute;
				content:"✔︎"; /* @seva need url from assets */
				font-size: 10px;
				line-height: 20px;
				text-align: center;
				color: white;
				width: 19px;
				height: 19px;
				top: -8px;
				right: -8px;
				border-radius: 10px;
				background-color: var(--asparagus);
			}
		}
	}

	.deal__overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(255, 255, 255, 0.3);
		display: flex;
		justify-content: center;
		align-items: center;
		transition: background .2s ease-in-out;

		p {
			color: black;
			font-size: 16px;
		}

		& > * {
			opacity: 0;
			transition: opacity .2s ease-in-out;
		}

		&:hover {
			background: rgba(255, 255, 255, 0.8);

			& > * {
				opacity: 1;
			}
		}
	}

	.deal__buttons {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		align-items: center;
		// transform: translateX(10px);
		padding-top: 20px;
		margin-top: 20px;
		position: relative;

		&:before {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 1px;
			background-color: var(--very-light-blue);
			display: block;
			content: "";
		}

		& > * + * {
			margin-left: 10px;
		}
	}

	.deal.archived {
		// opacity: 0.8;
	}

</style>
