<template>
  <div>
    <div class="dashboard-welcome">
      <div class="container">
        <h1>Welcome to your loan dashboard, {{ customer.firstName}}!</h1>
        <div class="subtitle" style="max-width: 900px;">
          You can process any type of multifamily, commercial property or small business loan through Janover, right here! Below you will see open deals you are working on, that you can edit in real time. Once you submit a loan; our system will quickly and automatically work to match you with the right lender. Good luck!
          <br>
          <br>
          Got some feedback or constructive criticism? Click <a target="_blank" :href="`https://share.hsforms.com/1PH2QGgWOS7SW0IAK-RLGXw54bhv?email=${customer.email}&firstname=${customer.firstName}&lastname=${customer.lastName}`">here</a> to tell us how we can do better!
        </div>
        <br>
        <button
          @click="handleNewDeal"
          class="btn  btn__primary small-only"
        >
          New loan request
        </button>
      </div>
    </div>

    <div v-if="deals">
      <div class="container mobile-full">
        <div
          class="dashboard-deals dashboard-deals__submitted"
          v-if="quotedDeals.length > 0"
        >
          <div class="dashboard-deals-header">
            <div class="title">
              Quoted requests <span class="dashboard-deals-num">{{ quotedDeals.length }}</span>
            </div>
          </div>

          <div class="dashboard-deals-list">
            <quoted-deal-preview v-for="(deal, index) in quotedDeals" :deal="deal" :key="index" />
          </div>
        </div>

        <div class="dashboard-deals dashboard-deals__active" v-if="activeDeals.length > 0">
          <div class="dashboard-deals-header">
            <div class="title">Loan requests <span class="dashboard-deals-num">{{ activeDeals.length }}</span></div>
            <button
              @click="handleNewDeal"
              class="btn  btn__primary big-only"
              :disabled="isLoading"
            >
              New loan request
            </button>
          </div>
          <div class="dashboard-deals-list">
            <deal-preview
              v-for="(deal, index) in activeDeals.reverse()"
              :deal="deal"
              :key="index"
            />
            <div class="deal deal__new">
              <button
                @click="handleNewDeal"
                class="btn  btn__primary"
                :disabled="isLoading"
              >
                New loan request
              </button>
            </div>
          </div>
        </div>
        
        <div class="dashboard-deals dashboard-deals__submitted" v-if="submittedDeals.length > 0">
          <div class="dashboard-deals-header">
            <div class="title">Submitted requests <span class="dashboard-deals-num">{{ submittedDeals.length }}</span></div>
            <button
              @click="handleNewDeal"
              class="btn  btn__primary"
              v-if="activeDeals.length < 1"
              :disabled="isLoading"
            >
              New loan request
            </button>
          </div>
          <div class="dashboard-deals-list">
            <deal-preview v-for="(deal, index) in submittedDeals.reverse()" :deal="deal" :key="index" />
          </div>
        </div>

        <div class="dashboard-deals dashboard-deals__archived" v-if="archivedDeals.length > 0">
          <div class="dashboard-deals-header">
            <div class="title">Archive <span class="dashboard-deals-num">{{ archivedDeals.length }}</span></div>
          </div>
          <div class="dashboard-deals-list">
            <deal-preview v-for="(deal, index) in archivedDeals.reverse()" :deal="deal" :key="index" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>



<script>

  import { mapGetters, mapActions } from 'vuex'
  import DealPreview from '@/components/DealPreview'
  import QuotedDealPreview from '@/components/QuotedDealPreview'
  
  export default {
    name: 'PageDashboard',

    components: {
      DealPreview,
      QuotedDealPreview
    },

    data () {
      return {
        isLoading: false
      }
    },

    computed: {
      ...mapGetters([
        'deals',
        'customer',
        'questions'
      ]),

      submittedDeals () {
        return this.deals.filter(deal => {
          return deal.submitted && !this.isClosedDeal(deal)
        })
      },

      activeDeals () {
        return this.deals.filter(deal => {
          return !deal.submitted && !deal.archived
        })
      },

      archivedDeals () {
        return this.deals.filter(deal => this.isClosedDeal(deal))
      },

      quotedDeals () {
        return this.deals.filter(({ softQuotes }) => softQuotes && softQuotes.length)
      }
    },

    methods: {
      ...mapActions([
        'getDeals',
        'showModal'
      ]),

      isClosedDeal (deal) {
        return !!deal.archived || deal.status == 'closed' || deal.status == 'closed won'
      },

      handleNewDeal () {
        this.showModal({
          name: 'NewDeal'
        })
      },

      getDateString (timestamp) {
        const date = new Date(timestamp)
        const monthNames = [
          "January", "February", "March", "April", "May", "June",
          "July", "August", "September", "October", "November", "December"
        ]

        return `${monthNames[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`
      }
    },

    created () {
      if (!this.deals || this.deals.length < 2) this.getDeals() // one deal might be there if we opened a deal from the email
    }
  }

</script>

<style lang='scss' scoped>
  .dashboard-welcome {
    background-color: var(--dark-grey-blue);
    color: white;
    padding-top: 60px;
    padding-bottom: 250px;
    margin-bottom: -172px;
    h1 {
      margin-bottom: 10px;
    }
    .subtitle {
      font-size: 15px;
      color: #ffffff;
    }
  }
  
  .dashboard-deals-header {
    display: flex;
    align-items: center; 
    justify-content: space-between;
    
    .title {
      font-size: 22px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.09;
      letter-spacing: normal;
      color: var(--dark-grey-blue);
    }
  }

  .dashboard-deals-num {
    border-radius: 10px;
    background-color: var(--sky-blue);
    height: 25px;
    line-height: 25px;
    min-width: 25px;
    font-size: 12px;
    font-weight: 600;
    color: #ffffff;
    display: inline-block;
    text-align: center;
    margin-left: 5px;
    margin-top: -0.05em;
    vertical-align: middle;
  }
  
  .dashboard-deals-list {
    margin: 30px -7.5px;
  }

  .dashboard-deals + .dashboard-deals {
    margin-top: 25px;
  }

  .dashboard-deals {
    background: white;
    padding: 40px 30px 40px 40px;
    border-radius: 20px;
    min-height: 380px;
    
    &__archived,
    &__submitted {
      min-height: 40px;
      
      .dashboard-deals-num {
        background-color: var(--very-light-blue);
        color: var(--dark-grey-blue);
      }
      
      .deal {
        min-height: 200px;
      }
    }
  }
  
  .deal__new {
    background: none !important;
    border: 3px dashed #e7e8ec;
    display: inline-flex !important;
    align-items: center !important;
    justify-content: center !important;
    min-height: 278px;
    width: calc(33% - 11px);
    margin: 7.5px;
    border-radius: 16px;
    box-sizing: border-box;
    
    &:hover {
      box-shadow: none !important;
    }
  }

  @media screen and (max-width: 664px){
    .dashboard-deals {
      border-radius: 16px;
      padding: 40px 17px;
      min-height:0;
      &-header {
        justify-content: center;
      }
    }

    .dashboard-welcome {
      padding-top: 35px;
      margin-bottom: -20px;
      padding-bottom: 70px;
      text-align: center;
    }

    .container {
      width: 100%;
      box-sizing: border-box;
      &.mobile-full {
        padding: 0;
      }
    }

    .dashboard-deals-list .deal {
      width: 100%;
      min-width: 0;
      box-sizing: border-box;
      margin-left: 0;
      margin-right: 0;
      min-height: 0;
    }

    .dashboard-deals-list {
      margin: 10px 0;
    }

    .dashboard-welcome {
      h1 {
        font-size: 24px;
        font-weight: 600;
        line-height: 1.17;
      }
      .subtitle {
        font-size: 14px;
        line-height: 1.5;
      }
    }

    .dashboard-deals-header .title {
      font-size: 18px;
    }

    .deal__new {
      min-height: 202px !important;
    }
  }
  .subtitle a { 
    color: #fff;
    text-decoration: underline;
  }
</style>