<template>
	<div
		class="q"
		:id="`question-${question._id}`"
		:class="{
			'is-previous': !isCurrent,
			'is-active': isCurrent,
			'is-skipped': !value || (value.hasOwnProperty('day') && value.day == '')
		}"
		@click="extend"
	>
		<form  @submit.prevent="handleFormSubmit" >
			<div class="q-index">{{index + 1}}</div>
			<div class="q-content">
				<div class="q-heading">
					<span v-if="isCurrent">{{ question.subtitle }}</span>
					<template v-else>
						<span>{{ question.title }}</span>
						<template v-if="value">
							<span class="small" v-if="question.type == 'document'"> – Uploaded</span>
							<span class="small" v-else-if="question.appearance == 'address'"> – {{ value.administrative_area_level_1 + ',' || ''}} {{ value.route }}, {{ value.postal_code }}</span>
							<span class="small" v-else-if="question.appearance == 'currency'"> – ${{ value }}</span>
							<span class="small" v-else> – {{ value }}</span>
						</template>
					</template>
					<span class="q-heading__status" v-if="!isCurrent" :class="!value || (value.hasOwnProperty('day') && value.day == '') ? 'red' : 'green'"></span>
				</div>
				<div class="q-text" v-if="isExtended">{{ question.hint }}</div>

				<div class="q-answers" v-if="isExtended">
					<template v-if="question.type == 'boolean' || question.type == 'selector'">
						<div class="question-optionbnts-wrapper">
							<label
								v-for="(option, index) in question.options"
								:key="index"
								class="q-optionlabel"
								:class="{ 'is-active': value === option }"
							>
								<div class="check"></div>
								<input type="radio" :value="option" v-model="value" hidden @click="handleOptionClick">
								<span>{{ option }}</span>
							</label>
						</div>    
					</template>

					<template v-else-if="question.type == 'document'">
						<div class="file-value" v-if="value.length">
							<template v-if="Array.isArray(value)">
								<div
									class="file-single"
									v-for="(file, index) in value"
									:key="index"
								>
									<p>Attachment #{{index+1}}</p>	
									<div>
										<a :href="file" target="_blank">Download</a>
										<a
											href="#remove"
											class="remove"
											@click.prevent="removeFile(index)"
										>
											Remove
										</a>
									</div>
								</div>
							</template>
							<div
								class="file-single"
								v-else
							>
								<p>Attachment #1</p>	
								<div>
									<a :href="value" target="_blank">Download</a>
									<a
										href="#remove"
										class="remove"
										@click.prevent="removeFile(0)"
									>
										Remove
									</a>
								</div>
							</div>
						</div>

						<div class="file-wrap">
							<input
								type="file"
								@change="handleFileChange"
								multiple
							>
							
							<div class="file-upload-hint" v-if="!files.length">
								<div>
									Drag documents here or use upload button on&nbsp;the&nbsp;right
								</div>  
								<button class="btn btn__small btn__secondary">Upload</button>
							</div>
							<div
								class="file-upload-hint column"
								v-else
							>
								<p v-for="(file, index) in files" :key="index">{{ file.name }}</p>
							</div>
						</div>
					</template>

					<template v-else>
						<div class="form-input-wrap form-date" v-if="question.appearance == 'date'">
							<div
								v-for="field in ['month', 'day', 'year']" :key="field"
							>
								<label>
									<span>{{ field }}</span>
									
									<div class="select-wrap">
										<select
											v-model="value[field]"
											:class="{ 'muted': !value[field] }"
										>
											<option value="" :selected="true" disabled v-if="!value[field]">...</option>
											<option
												v-for="option in dateOptions[field]"
												:key="`${field}-${option}`"
											>
												{{ option }}
											</option>
										</select>
									</div>
								</label>
							</div>
						</div>

						<vue-google-autocomplete
							ref="address"
							v-else-if="question.appearance == 'address'"
							:id="`map-${question._id}`"
							classname="address-input"
							:placeholder="question.title || 'Start typing...'"
							:options="{fields: ['name', 'geometry', 'formatted_address', 'address_components']}"
							:country="question.title.includes('Home') ? [] : ['us']"
							v-on:placechanged="getAddressData"
						/>
						
						<div
							v-else-if="question.type == 'numeric'"
							class="q-input-wrapper"
							:class="{ 'currency': question.appearance == 'currency' }"
						>
							<input type="text" v-model="value" inputmode="numeric" autofocus>
						</div>
						<div
							v-else-if="question.appearance == 'textarea'"
							class="q-input-wrapper"
						>
							<textarea type="text" v-model="value" autofocus></textarea>
						</div>
						<div
							v-else
							class="q-input-wrapper text"
						>
							<input type="text" v-model="value" autofocus>
						</div>
					</template>
				</div>

				<div
					class="q-actions"
					:class="{ 'state-submit': isLastQuestion }"
					v-if="question.type !== 'selector' && question.type !== 'boolean' && isExtended"
				>
					<button
						v-if="isLastQuestion"
						class="btn"
						:disabled="isLoading || deal.submitted"
						type="submit"
						key="submit-deal"
					>
						Submit the deal
					</button>
					
					<template v-else>
						<div>
							<button
								class="btn btn__skip"
								type="button"
								:disabled="isLoading"
								@click="skipQuestion"
								key="skip"
								v-if="canSkip && question.name !== 'Loan amount'"
							>
								Skip question
							</button>
						</div>

						<div>
							<button
								class="btn btn__back"
								type="button"
								:disabled="isLoading"
								@click="showPreviousQuestion"
								key="back"
							>
								<div class="icon-back"></div>
								Back
							</button>

							<button
								class="btn btn__primary btn__next"
								type="submit"
								:disabled="!isValid || isLoading"
								key="submit"
							>
								Next step
								<div class="icon-next"></div>
							</button>
						</div>
					</template>
				</div>
			</div>
		</form>
	</div>
</template>



<script>
	
	import VueGoogleAutocomplete from 'vue-google-autocomplete'
	import { mapActions, mapGetters } from 'vuex'
	import convertToCurrency from '@/helpers/convertToCurrency.js'
	import getIcon from '@/helpers/getIcon.js'

	import mixpanel from '@/helpers/mixpanel.js'

	export default {
		name: 'QuestionnaireQuestion',

		data () {
			return {
				value: '',
				files: [],
				beenChanged: false,
				isLoading: false,
				isToggled: false,
				dateOptions: {
					day: Array.from({length: 31}, (_, i) => ('0' + (i + 1)).slice(-2)),
					month: Array.from({length: 12}, (_, i) => ('0' + (i + 1)).slice(-2)),
					year: Array.from({length: 100}, (_, i) => new Date().getFullYear() - i)
				},
				stepStarted: new Date()
			}
		},

		components: {
			VueGoogleAutocomplete
		},

		props: {
			index: {
				type: Number,
				required: true
			},
			question: {
				type: Object,
				required: true
			},
			deal: {
				type: Object,
				required: true
			},
			isCurrent: {
				type: Boolean,
				default: true
			},
			isLastQuestion: {
				type: Boolean,
				default: false
			},
			canSkip: {
				type: Boolean,
				default: true
			},
			estimatedProgress: {
				type: [Number, String]
			},
		},

		computed: {
			...mapGetters([
				'customer'
			]),

			isValid () {
				if (this.question.appearance == 'address' && typeof this.value !== 'object') return false
				if (this.question.type == 'text' && this.question.appearance == 'date') {
					return Object.values(this.value).filter(value => !value).length < 1
				}
				if (this.question.type == 'document') return !!this.beenChanged
				return !!this.value
			},

			isExtended () {
				return this.isCurrent
			}
		},

		methods: {
			...mapActions([
				'updateDeal',
				'uploadFile',
				'showModal',
				'notify'
			]),

			getIcon,
			convertToCurrency,

			extend () {
				if (!this.isCurrent) this.$emit('active-question-changed', this.index)
			},

			showPreviousQuestion () {
				let from = this.question.name;
				if (this.index > 0) this.$emit('active-question-changed', this.index - 1)
				mixpanel.track("Stepped back", {
					"From": from,
					"To": this.question.name
				});
			},

			async processUpdate () {
				try {
					await this.$store.dispatch('updateDeal', {
						id: this.deal.id,
						data: { 
							[this.question.name]: this.value,
							questionType: this.question.type,
							estimatedProgress: this.estimatedProgress
						},
						estimatedProgress: this.estimatedProgress
					})

					if (this.value) {

						mixpanel.track("Step completed", {
							"Field name": this.question.name,
							"Question": this.question.subtitle,
							"Question type": this.question.type,
							"Question appearance": this.question.appearance,
							"Answer": this.value,
							"Time to answer in seconds": (new Date() - this.stepStarted) / 1000,
							"Estimated progress": this.estimatedProgress,
							"Question index": this.index + 1,
							"Deal ID": this.deal.id
						})

						window.analytics.track(
							"Questionnaire step completed", {
								"step_name": this.question.name,
								"step_question": this.question.subtitle,
								"step_type": this.question.type,
								"step_appearance": this.question.appearance,
								"step_answer": this.value,
								"step_time_to_answer": (new Date() - this.stepStarted) / 1000,
								"step_progress": this.estimatedProgress,
								"step_position": this.index + 1,
								"deal_id": this.deal.id,
								[this.question.name]: this.value
							}
						)
					}

					mixpanel.people.set({
						[this.question.name]: this.value
					});

					mixpanel.people.increment("Steps completed");
					
					if (this.isLastQuestion) {
						mixpanel.track("Modal window shown", {
							"Modal name": "Deal submission confirmation"
						})

						await this.updateDeal({
							id: this.deal._id,
							submitted: true
						})
						
						window._hsq.push(["identify",{
							email: this.customer.email,
							last_deal_summary: `https://lenders.janover.ventures/deal/${this.deal._id}`
						}]);

						window._hsq.push(["trackEvent", { 
							id: "Portal – Deal submitted"
						}]);

						window.analytics.track("Questionnaire finished",{
							"deal_id": this.deal.id
						});

						this.showModal({
							name: 'SubmitDeal',
							data: {
								dealId: this.deal.id
							}
						})
					} else {
						this.confirmUpdate()
					}

					this.isLoading = false
					this.isToggled = false
				} catch(e) {
					console.log(e)
					this.isLoading = false
				}
			},

			removeFile (index) {
				if (Array.isArray(this.value)) {
					this.value = this.value.filter((item, i) => i !== index)
					this.beenChanged = true
				} else {
					this.value = ''
					this.beenChanged = true
				}
			},

			skipQuestion () {
				mixpanel.track("Step skipped", {
					"Field name": this.question.name,
					"Question": this.question.subtitle,
					"Question type": this.question.type,
					"Question appearance": this.question.appearance,
					"Time to skip in seconds": (new Date() - this.stepStarted) / 1000,
					"Question index": this.index + 1
				})
				mixpanel.people.increment("Steps skipped");
				window.analytics.track("Questionnaire step skipped", {
					"step_name": this.question.name,
					"step_question": this.question.subtitle,
					"step_type": this.question.type,
					"step_appearance": this.question.appearance,
					"step_time_to_answer": (new Date() - this.stepStarted) / 1000,
					"step_progress": this.estimatedProgress,
					"step_position": this.index + 1,
					"deal_id": this.deal.id
				});
				this.handleFormSubmit()
			},

			async processFileUpdate () {
				try {
					let value = [] 

					if (this.value) {
						if (Array.isArray(this.value)) value = this.value
						else value = [this.value]
					}

					for (let file of this.files) {
						const formData = new FormData()
							
						formData.append('file', file)
						formData.append('name', 'file')

						const response = await this.uploadFile(formData)
						value.push(response)
					}
					
					this.value = value

					await this.processUpdate()
				} catch(e) {
					this.isLoading = false
					this.notify({
						type: 'error',
						message: e.error || e.message || e
					})
				}
			},

			async handleFormSubmit () {
				if (!this.isLoading) {
					this.isLoading = true

					if (this.question.type == 'document' && this.beenChanged) {
						await this.processFileUpdate()
					} else {
						await this.processUpdate()
					}
				}
			},

			confirmUpdate () {
				console.log('confirmUpdate')
				this.isLoading = false
				this.beenChanged = false

				this.$emit('question-saved', {
					index: this.index,
					id: this.question._id,
					value: this.value
				})
			},

			handleFileChange (e) {
				this.files = (e.target?.files?.length) ? e.target.files : []
				this.$emit('input', this.files.length ? this.files : null)
				this.beenChanged = !!this.files.length
			},

			handleOptionClick () {
				setTimeout(() => {
					this.handleFormSubmit()
				}, 500)
			},

			initValue () {
				if (this.deal.data) {
					this.value = this.deal.data[this.question.name] || ''
					
					if (!this.value && this.question.type == 'text' && this.question.appearance == 'date') {
						this.value = {
							day: '',
							month: '',
							year: ''
						}
					}
				}

				this.files = []
			},

			getAddressData (e) {
				console.log(e)
				this.value = e
			},

			updateAddress () {
				if (this.isCurrent && this.question.appearance == 'address' && typeof this.value == "object") {
					this.$refs.address.update(
						`${this.value.country}, ${this.value.administrative_area_level_1}, ${this.value.locality}, ${this.value.route}, ${this.value.postal_code}`
					)
				}
			}
		},

		watch: {
			value () {
				if (this.question.type == 'numeric') {
					if (this.question.appearance == 'currency') {
						this.value = convertToCurrency(this.value)
					} else if (typeof this.value == 'string') {
						this.value = this.value.replace(/[^\d]/g,'')
					}
				}
			},

			deal () {
				if (this.question.type == 'document') this.initValue()
			},

			isCurrent () {
				this.$nextTick(() => {
					this.updateAddress()
				})
			}
		},

		created () {
			this.initValue()
		},

		mounted () {
			this.updateAddress()
		}
	}

</script>

<style lang="scss" scoped>
	.q {
		background-color: white;
		border-radius: 20px;
		padding: 35px 30px 30px 25px;
		margin: 10px;
		/* max-width: 900px; */
		width: 100%;
		box-sizing: border-box;
		form {
			display: flex;
			flex-direction: row;
			align-items: flex-start;
		}
		.address-input {
			width: 400px;
			max-width: 100%;
		}
		&.is-previous {
			cursor: pointer;
			.q-index {
				color: var(--blue-grey);
				margin-top: 0;
			}
			form {
				align-items: center;
			}

			.q-heading {
				font-size: 18px;
				color: var(--light-grey-blue);
				cursor: pointer;
				padding-right: 40px;
				line-height: 1.25;
				text-transform: capitalize;
			}
		}
		.currency {
			position: relative;
			&:after {
				content: "$";
				position: absolute;
				left: 22px;
				top:0px;
				height: 100%;
				display: flex;
				align-items: center;
				font-size: 15px;
				font-weight: 600;
				color: var(--blue-grey);
			}
		}
	}
	.small {
		color: var(--blue-grey);
		font-size: 0.8em;
	}
	.q-heading {
		font-size: 28px;
		font-weight: 600;
		line-height: 1.25;
		color: var(--dark-grey-blue);
		position: relative;
		

		&__status {
			position: absolute;
			border-radius: 15px;
			width: 24px;
			height: 24px;
			right: 0;
			top: 0;
			bottom: 0;
			margin: auto;
			display: block;

			&:before,
			&:after {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				margin: auto;
				display: block;
				content: "";
			}

			&.green {
				background: var(--light-sage);

				&:after {
					width: 6px;
					height: 3px;
					border: 2px solid var(--military-green);
					border-top: none;
					border-right: none;
					transform: rotate(-45deg);
				}
			}

			&.red {
				background: var(--light-peach);

				&:before,
				&:after {
					background: var(--dull-orange);
					width: 10px;
					height: 2px;
				}

				&:before {
					transform: rotate(45deg);
				}

				&:after {
					transform: rotate(-45deg);
				}
			}
		}
	}

	.q-index {
		width: 30px;
		height: 30px;
		border-radius: 15px;
		background-color: #f6f7fb; 
		display: inline-flex;
		align-items: center;
		justify-content: center;
		font-family: Poppins;
		font-size: 12px;
		font-weight: 600;
		color: var(--dark-grey-blue);
		margin-top: 3px;
		margin-right: 25px;
		flex: 0 0 30px;
	}

	.q-text {
		font-size: 15px;
		line-height: 26px;
		margin-top: 10px;
	}

	.q-content {
		width: 100%;
	}
	
	.q-answers {
		margin-top: 45px;
		&:last-child {
			margin-bottom: 25px;
		}
	}

	
	.q-optionlabel {
		padding: 26px 30px;
		border-radius: 16px;
		margin-right: 15px;
		margin-bottom: 15px;
		display: inline-block;
		width: calc(33% - 75px);
		position: relative;
		font-size: 15px;
		font-weight: 500;
		color: var(--dark-indigo);
		cursor: pointer;
		user-select: none;
		/* overflow: hidden; */
		display: inline-flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
		img {
			margin-right: 10px;
			width: 28px;
			height: 28px;
		}
		.check {
			position: absolute;
			content: "";
			display: block;
			right: 0;
			top: 0;
			width: 52px;
			height: 52px;
			overflow: hidden;
			border-radius: 16px;
			transform: scale(0);
			transform-origin: top right;
			transition: transform 0.2s ease;
			&:after{
				position: absolute;
				content: "";
				display: block;
				right: 0;
				top: 0;
				margin-right: -26px;
				margin-top: -27px;
				width: 52px;
				height: 52px;
				border-radius: 16px;
				background-color: var(--orange);
			}
		}
		&.is-active {
			.check {
				transform: scale(1);
			}
		}
		&:after {
			content: "";
			position: absolute;
			left:0;
			right:0;
			bottom:0;
			top:0;
			z-index: 1;
			border-radius: 16px;
			box-shadow: 0 5px 33px -11px rgba(51, 77, 97, 0.21);
			transition: box-shadow 0.2s ease-out;
		}
		&:hover{
			&:after {
				box-shadow: 0 18px 70px -18px rgba(236, 130, 14, 0.5);
			}
		}
	}

	.question-optionbnts-wrapper {
		margin-bottom: -15px;
	}
	
	.q-actions {
		margin-top: 40px;
		text-align: right;

		& > * + * {
			margin-left: 15px;
		}
		&.state-submit {
			display: block;
		}
	}

	.file-wrap { /* TODO move to component */
		min-height: 128px;
		border-radius: 16px;
		border: dashed 1px var(--light-grey-blue);
		box-sizing: border-box;
		width: 100%;
		position: relative;
		font-size: 13px;
		font-weight: 500;
		color: var(--dark-indigo);
		display: flex;
		align-items: center;
		justify-content: center;
		max-width: 525px;
		padding: 40px;
		
		input {
			position: absolute;
			top: 0;
			left:0;
			right:0;
			bottom: 0;
			opacity: 0;
			width: 100%;
		}
		
		.file-upload-hint {
			display: flex;
			justify-content: space-between;

			&.column {
				flex-direction: column;
			}

			p {
				margin: 0;
			}

			p + p {
				margin-top: 10px;
			}
			button {
				margin-left: 40px;
			}
		}
		
		&:hover .btn {
			background-color: var(--powder-blue);
		}
	}
	
	@media screen and (max-width: 664px) { 
		.intro-page .content.signup {
			/* padding: 20px 17px; */
		}
		.q{
			padding: 25px 20px;
						margin: 5px 0;
			&-heading {
				font-size: 21px;
								padding-right: 35px;
			}
			&-text {
				font-size: 14px;
				line-height: 1.5;
			}
			&-index {
				margin-top: 3px;
			}
			&-answers {
				margin-left: -55px;
				margin-top: 35px;
			}
			&-optionlabel {
				width: 100%;
				box-sizing: border-box;
				margin: 0 0 10px 0;
			}
			&-actions {
				flex-direction: column-reverse;
				width: calc(100% + 55px) !important;
				margin: 40px 0 0 -55px !important;
				.btn__back {
					display: none;
				}
				.btn__next {
					width: 100%;
					margin-bottom: 15px;
				}
				& > * + *  {
					margin-left: 0;
				}
				/* div {
					width: 100%;
					text-align: center;
				} */
			}
			input[type=text]{
				width: 100%;
				box-sizing: border-box;
			}
			&-footer {
				padding-top: 0;
				width: 100%;
			}
			&.is-previous {
				form {
					align-items: center;
					justify-content: center;
				}
				.q-heading {
					font-size: 16px;
					font-weight: 500;
					line-height: 1.5;
					margin-top: 3px;
				}
			}
			&-heading__status {
				top: -1px;
			}
		}

		.file-wrap {
			height: auto;
			text-align: center;

			.file-upload-hint {
				flex-direction: column;
				align-items: center;
				justify-content: center;

				div{
					display: none;
				}
			}

			.btn {
				margin:  0 !important;
			}
		}
	}

	.q-input-wrapper.text {
		input {
			width: 100%;
			max-width: 25em;
		}
	}

	.q-input-date {
		display: flex;
		flex-direction: row;
		label {
			flex-direction: column;
			align-items: flex-start;
			span {
				display: block;
				font-size: 13px;
				color: var(--dark-grey-blue);
				text-transform: capitalize;
			}
		}
		span {
			display: block;
		}
		select {
			border-radius: 10px;
			border: solid 1px #e2e6ec;
			background-color: #f7f8fc;
			width: 160px;
			height: 60px;
			padding: 20px 50px 20px 25px;
			-webkit-appearance: none;
			appearance: none;
			font-size: 15px;
			outline:none;
			&:hover, &:focus {
				border-color: var(--blue-grey);
			}
			/* color: var(--light-grey-blue); */
		}
	}

	.q-actions {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-left: -35px;
	}

	.select-wrap {
		position: relative;
		margin-top: 15px;
		margin-right: 40px;

		&:after {
			content: "";
			width: 25px;
			height: 25px;
			border-radius: 6px;
			/* background-color: var(--very-light-blue); */
			position: absolute;
			background: var(--very-light-blue) url('~@/assets/icons/select-arrow.svg') center no-repeat;
			top: 50%;
			margin-top: -12px;
			right: 15px;
			display: block;
			pointer-events: none;
		}

		&:hover {
			&:after {
				background: var(--powder-blue) url('~@/assets/icons/select-arrow.svg') center no-repeat;
			}
		}

		select.muted {
			color: var(--light-grey-blue);
		}
	}

	.file-value {
		border: solid 1px var(--light-grey-blue);
		border-radius: 16px;
		max-width: 525px;
		margin-bottom: 16px;

		.file-single {
			display: flex;
			justify-content: space-between;
			padding: 16px 40px;
			font-size: 13px;

			&:not(:first-child) {
				border-top: 1px solid var(--light-grey-blue);
			}

			a + a {
				margin-left: 10px;

				&.remove {
					color: var(--vermillion);
				}
			}
		}

		p {
			margin: 0;
			font-weight: 500;
			color: var(--dark-indigo);
		}
	}
</style>
