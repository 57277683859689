export default {
  fields: {
    firstName: {
      model: 'firstName',
      type: 'text',
      label: 'First Name',
      placeholder: 'John',
      validations: ['required', 'isNameString'],
      editable: true
    },

    lastName: {
      model: 'lastName',
      type: 'text',
      label: 'Last Name',
      placeholder: 'Doe',
      validations: ['required', 'isNameString'],
      editable: true
    },

    phone: {
      model: 'phone',
      type: 'text',
      label: 'Phone Number',
      placeholder: 'XXX XX XXXX',
      validations: ['required', 'isPhone'],
      mask: '(999) 999-9999',
      editable: true
    },

    email: {
      model: 'email',
      type: 'email',
      label: 'Your Email',
      placeholder: 'me@example.com',
      validations: ['required', 'isEmail'],
      editable: false
    }
  }
}