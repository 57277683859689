<template>
	<div>
		<!-- Waiting for authorization... -->
	</div>
</template>



<script>
	
	import { mapActions, mapGetters } from 'vuex'

	export default {
		name: 'PageAuth',

		computed: {
			...mapGetters([
				'isAuth',
				'customer'
			])
		},

		methods: {
			...mapActions([
				'notify',
				'authorizeCustomer',
				'showModal'
			])
		},

		watch: {
			isAuth () {
				if (this.isAuth) {
					// const urlParams = new URLSearchParams(window.location.search);
					// urlParams.get('aid') && window.analytics?.setAnonymousId && window.analytics.setAnonymousId(urlParams.get('aid'))
					if (this.$route.query.welcome) {
						const { __hstc, __hssc, __hsfp, hutk } = this.$route.query;
						const hubspotString = __hstc && __hssc && __hsfp && hutk ? "?__hstc="+ __hstc +"&__hssc="+ __hssc +"&__hsfp="+ __hsfp +"&hutk="+ hutk : "" ;
						
						this.$router.push(`/deal/${this.customer.deals[0]}${hubspotString}`)

						this.showModal({
							name: 'Welcome'
						})
					} else {
						console.log(this.customer.deals.length)
						if (this.customer.deals.length > 0) {
							this.$router.push(`/deal/${this.customer.deals[this.customer.deals.length - 1]}`)
						} else {
							this.$router.push('/')
						}
					}
				}
			}
		},

		async mounted () {
			if (this.$route.query?.token) {
				let token = this.$route.query.token

				if (token == 'returning-customer') {
					this.$router.push({
						name: 'Message',
						params: {
							message: '<h1>Account with provided email already exists.</h1><p>Please, check your email for login link.</p>'
						}
					})
				} else {
					try {
						const result = await this.authorizeCustomer(this.$route.query?.token)

						if (!result) {
							this.notify({
								message: 'Your authorization link may be expired',
								type: 'error'
							})
							window.analytics.track('Expired authorization link')
							this.$router.push('/sign-in#login')
						}
					} catch(e) {
						this.notify({
							message: 'Your authorization link may be expired',
							type: 'error'
						})
						window.analytics.track('Expired authorization link')
						this.$router.push('/sign-in#login')
					}	
				}
			}
		}
	}
</script>